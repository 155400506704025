import './bootstrap';

// import {Carousel, Fancybox} from '@fancyapps/ui';

import '@fancyapps/fancybox/dist/jquery.fancybox'




function positionDropdownOnMobile() {
    let secondaryMenu = $('.secondary-menu .dropdown-secondary-menu');
    let winWidth = $('header .container').width();

    if (winWidth < 993) {
        secondaryMenu.css('width', winWidth + 'px');

    } else {
        secondaryMenu.css('width', 'auto');

    }

}

$(document).ready(function() {

    // $('.gallery-main-image').click(function(e) {
    //     e.preventDefault();
    //     $.fancybox.open({
    //         src: $(this).find('a').attr('href'),
    //         type: 'image',
    //     });
    // });
    //
    //
    // $('[data-thumbs]').click(function(e) {
    //     e.preventDefault();
    //     var imageUrl = $(this).attr('href');
    //
    //
    //     $('.gallery-main-image a').attr('href', imageUrl);
    //     $('.gallery-main-image img').attr('src', imageUrl);
    // });


// Обработчик клика на превью изображения
    $('[data-thumbs]').click(function(e) {
        e.preventDefault();
        $('[data-thumbs]').removeClass('active');
        $(this).addClass('active');

        var imageUrl = $(this).attr('href');

        // Обновляем изображение в блоке gallery-main-image
        $('.gallery-main-image a').attr('href', imageUrl);
        $('.gallery-main-image img').attr('src', imageUrl);
    });

// Обработчик клика на изображение в блоке gallery-main-image
    $('#gallery-main-image').click(function(e) {
        e.preventDefault();

        // Получите URL текущего изображения из блока gallery-main-image
        var currentImageUrl = $('.gallery-main-image a').attr('href');

        if (currentImageUrl) {
            var group = [];
            $('[data-thumbs]').each(function() {
                group.push({
                    src: $(this).attr('href'),
                    type: 'image'
                });
            });

            // Найдите индекс текущего изображения в группе
            var currentIndex = group.findIndex(function(item) {
                return item.src === currentImageUrl;
            });

            if (currentIndex >= 0) {
                $.fancybox.open(group, {
                    loop: true,
                    index: currentIndex // Укажите индекс текущего изображения
                });
            }
        }
    });


    let thumbsGalleryHeight = $('.gallery-main-image a img').height();
    // $('.thumbs').css('height', thumbsGalleryHeight + 'px');


    $('.product-view .description-box .tabs a').click(function (e) {
        e.preventDefault()

        $('.product-view .description-box .tabs a').removeClass('active');
        $(this).addClass('active');
        $('.product-view .description-box .items').addClass('d-none');
        $('.product-view .description-box').find('.' + $(this).data('id')).removeClass('d-none')


    })


    $('.product-view .period input[name="price"]:first').prop('checked', true);
    $('.product-view .cost #priceValue').text($('.product-view .period input[name="price"]:first').val());

    $('.product-view form').attr('action', $('.product-view .period input[name="price"]:first').data('url'));

    $('.product-view .period input[name="price"]').change(function () {
        changePriceUrl(this)

    });

    $('input[name="agreement1"], input[name="agreement2"]').change(function () {
        agreementCheck()
    });




    let isLangMenuOpen = false;
    $(".language-menu .dropdown-button").click(function(e) {
        e.preventDefault()

        if (isLangMenuOpen) {
            $(this).removeClass("active");
            $(this).find('svg.closed').removeClass("d-none");
            $(this).find('svg.opened').addClass("d-none");
            $(".language-menu .dropdown-language-menu").removeClass("opened");

        } else {
            $(this).addClass("active");
            $(this).find('svg.closed').addClass("d-none");
            $(this).find('svg.opened').removeClass("d-none");
            $(".language-menu .dropdown-language-menu").addClass("opened");
        }

        isLangMenuOpen = !isLangMenuOpen;
    });

    let isMenuOpen = false;
    $(".secondary-menu .dropdown-button").click(function(e) {
        e.preventDefault()

        if (isMenuOpen) {
            $(".secondary-menu .dropdown-secondary-menu").removeClass("opened");
            $(".secondary-menu .dropdown-button .menu-opened").addClass("d-none");
            $(".secondary-menu .dropdown-button .menu-closed").removeClass("d-none");

        } else {
            $(".secondary-menu .dropdown-secondary-menu").addClass("opened");
            $(".secondary-menu .dropdown-button .menu-opened").removeClass("d-none");
            $(".secondary-menu .dropdown-button .menu-closed").addClass("d-none");
        }

        isMenuOpen = !isMenuOpen;
    });

    $(document).click(function(event) {
        let targetLang = $(event.target);
        if (!targetLang.closest(".language-menu").length && isLangMenuOpen) {
            $('.language-menu .dropdown-button').removeClass("active");
            $('.language-menu .dropdown-button').find('svg.closed').removeClass("d-none");
            $('.language-menu .dropdown-button').find('svg.opened').addClass("d-none");
            $(".language-menu .dropdown-language-menu").removeClass("opened");

            isLangMenuOpen = false;
        }

    });

    $(document).click(function(event) {
        let target = $(event.target);
        if (!target.closest(".secondary-menu").length && isMenuOpen) {
            $(".secondary-menu .dropdown-secondary-menu").removeClass("opened");
            $(".secondary-menu .dropdown-button .menu-opened").addClass("d-none");
            $(".secondary-menu .dropdown-button .menu-closed").removeClass("d-none");

            isMenuOpen = false;
        }

    });


    $(".slider").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        arrows: false,
        prevArrow: '<button class="slick-prev">Previous</button>',
        nextArrow: '<button class="slick-next">Next</button>',
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
    });



    positionDropdownOnMobile();
    $(window).resize(positionDropdownOnMobile);

    agreementCheck();
    changePriceUrl()


});

function agreementCheck() {
    if (!$('input[name="agreement1"]').is(':checked') || !$('input[name="agreement2"]').is(':checked')) {
        $('button[type=submit]').prop('disabled', true);
    } else {
        $('button[type=submit]').prop('disabled', false);
    }

}

function changePriceUrl(el) {
    if(!el) {
        el = $('.product-view .period input[name="price"]:checked');
    }

    const selectedValue = $(el).val();
    const selectedUrl = $(el).data('url');
    const id_d = selectedUrl.match(/id_d=(\d+)/)[1];

    $('.product-view .cost #priceValue').text(selectedValue);


    if(selectedUrl.indexOf('digiseller.market') !== -1) {
        const hiddenInput = $('<input>', {
            type: 'hidden',
            name: 'id_d',
            value: id_d
        });

        $('.product-view form input[name="id_d"]').remove();
        $('.product-view form').append(hiddenInput);
    }

    $('.product-view form').attr('action', selectedUrl);

}

